// @ts-ignore
import Headroom from "headroom.js";
import Swiper from 'swiper';
import { Navigation, Controller, Pagination } from 'swiper/modules';

// Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '/src/scss/main.scss';

// Dark Mode check
function applyDarkMode(): void {
    const theme: string = localStorage.getItem('theme');
    if (theme === 'dark' || (!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
}

function setTheme(theme: 'dark' | 'light'): void {
    localStorage.setItem('theme', theme);
    if (theme === 'dark') {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
}

function toggleTheme(): void {
    const currentTheme: string = localStorage.getItem('theme');
    if (currentTheme === 'dark') {
        setTheme('light');
    } else {
        setTheme('dark');
    }
}

function setupMenu(): void {
// MENU CODE TEMP --------------------------------------------
    const openMenuButton: HTMLElement = document.querySelector('#menuOpenButton');
    openMenuButton.addEventListener('click', (): void => {
        document.body.classList.toggle('menu-is-open');
    });

    const closeMenuButton: HTMLElement = document.querySelector('#menuCloseButton');
    closeMenuButton.addEventListener('click', (): void => {
        document.body.classList.remove('menu-is-open');
    });

    const contrastButton: HTMLElement = document.querySelector('#contrastButton');
    if (contrastButton) {
        contrastButton.addEventListener('click', toggleTheme);
    }

    const clickTriggers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-click-trigger]');
    const searchPanelOverlay: HTMLDivElement = document.querySelector('.search__overlay');
    const searchPanelInput: HTMLInputElement = document.querySelector('#panelSearchInput');
// @ts-ignore
    [...clickTriggers].forEach(trigger => {
        trigger.addEventListener('click', (e: Event): void => {
            e.preventDefault();
            const triggerType: string | undefined = trigger.getAttribute('data-click-trigger');

            switch (triggerType) {
                case 'search:open':
                    searchPanelOverlay.classList.add('is-visible');
                    searchPanelInput.focus();
                    break;
                case 'search:close':
                    searchPanelOverlay.classList.remove('is-visible');
                    break;
                default:
                    console.error(`Trigger type '${triggerType}' does not exist.`);
            }
        });
    });
}

function setupHeader(): void {
    const header: HTMLElement = document.querySelector('.l-header');
    const headroom = new Headroom(header, {
        offset: {
            up: 100,
            down: 100
        },
    });
    headroom.init();

    const message: HTMLElement = document.querySelector('.top-message');
    const messageButton: HTMLElement = document.querySelector('.top-message .button-close');
    if (messageButton) {
        messageButton.addEventListener('click', (): void => message.classList.add('hidden'));
    }
}

const newsReel: Swiper = new Swiper('.news-reel__outer .news-reel', {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
        640: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
    },
    navigation: {
        nextEl: '.news-reel__outer .swiper-button-next',
        prevEl: '.news-reel__outer .swiper-button-prev',
    },
});

new Swiper('.jobs-reel__outer .jobs-reel', {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
        640: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        }
    },
    navigation: {
        nextEl: '.jobs-reel__outer .swiper-button-next',
        prevEl: '.jobs-reel__outer .swiper-button-prev',
    },
});

let storyImages: Swiper = new Swiper('.story-images', {
    modules: [Navigation, Controller],
    loop: false,
    slidesPerView: 1,
    navigation: {
        nextEl: '.story-button-next',
        prevEl: '.story-button-prev',
    },
});
let storyContent: Swiper = new Swiper('.story-content', {
    modules: [Controller],
    loop: false,
    slidesPerView: 1,
    effect: 'fade',
});

storyImages.controller.control = storyContent;
storyContent.controller.control = storyImages;

// CONTENT IMAGE SWIPER --------------------------------------------
new Swiper('.image-content-swiper', {
    modules: [Navigation, Pagination],
    loop: false,
    slidesPerView: 1,
    navigation: {
        nextEl: '.image-content-swiper-outer .swiper-button-next',
        prevEl: '.image-content-swiper-outer .swiper-button-prev',
    },
    pagination: {
        el: '.image-content-swiper-outer .swiper-pagination',
        type: 'bullets',
    },
});

const locationsReel: Swiper = new Swiper('.locations-reel__outer .locations-reel', {
    modules: [Navigation],
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
        640: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        }
    },
    navigation: {
        nextEl: '.locations-reel__outer .swiper-button-next',
        prevEl: '.locations-reel__outer .swiper-button-prev',
    },
});


// THEME SWIPER --------------------------------------------
let themeSwiper: Swiper;
let init: boolean = false;

function themeSwiperCallback(): void {
    if (window.innerWidth <= 768) {
        if (!init) {
            init = true;
            themeSwiper = new Swiper('.themes-swiper', {
                modules: [ Navigation, Controller ],
                loop: false,
                slidesPerView: 1,
                spaceBetween: 20,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                    }
                },
                navigation: {
                    nextEl: '.themes-swiper-outer .swiper-button-next',
                    prevEl: '.themes-swiper-outer .swiper-button-prev',
                },
            });
        }
    } else if (init) {
        themeSwiper.destroy();
        init = false;
    }
}
themeSwiperCallback();
window.addEventListener('resize', themeSwiperCallback);

//
// Home Wayfinder
//
const tabs: NodeListOf<Element> = document.querySelectorAll('.wayfinder__tab');
// @ts-ignore
[...tabs].forEach(tab => {
    tab.addEventListener('click', function(e: Event): void {
        e.preventDefault();

        const targetId = this.getAttribute('data-target-content');
        const targetContent: Element = document.querySelector(`[data-content-id="${targetId}"]`);

        // Toggle is-selected class
        tabs.forEach((t: Element) => t.classList.remove('is-selected'));
        this.classList.add('is-selected');

        // Toggle is-visible class
        const visibleContent: Element = document.querySelector('.wayfinder__content.is-visible');
        if (visibleContent) {
            visibleContent.classList.remove('is-visible');
        }
        targetContent.classList.add('is-visible');
    });
});

//
// Accordion code (FAQ)
//
const accordions: NodeListOf<Element> = document.querySelectorAll('.accordion');
// @ts-ignore
[...accordions].forEach(item => {
    item.addEventListener('click', (e: Event): void => {
        const activePanel: Element = (e.target as HTMLElement).closest('.accordion-panel');
        if (!activePanel) return;
        toggleAccordion(activePanel);
    });
});

function toggleAccordion(panelToActivate): void {
    const activeButton = panelToActivate.querySelector('button');
    const activePanelIsOpened = activeButton.getAttribute('aria-expanded');

    if (activePanelIsOpened === 'true') {
        panelToActivate
          .querySelector('button')
          .setAttribute('aria-expanded', 'false');

        panelToActivate
          .querySelector('.accordion-content')
          .setAttribute('aria-hidden', 'true');
    } else {
        panelToActivate
          .querySelector('button')
          .setAttribute('aria-expanded', 'true');

        panelToActivate
          .querySelector('.accordion-content')
          .setAttribute('aria-hidden', 'false');
    }
}


//
// Specialists & News Filters
//
function expandPanel() {
    const button: HTMLButtonElement = document.querySelector('#show-more-options');

    if (button) {
        const panel: HTMLDivElement = document.querySelector('#more-options');

        button.addEventListener('click', (e: Event): void => {
            e.preventDefault();
            const panelIsOpen: string = panel.getAttribute('aria-hidden');
            button.setAttribute('aria-expanded', panelIsOpen == 'true' ? 'true' : 'false');
            panel.setAttribute('aria-hidden', panelIsOpen == 'true' ? 'false' : 'true');
        });
    }
}

function toggleFilterPanel(): void {
    const button: HTMLButtonElement = document.querySelector('[data-filters-button]');

    if (button) {
        const panel: HTMLDivElement = document.querySelector('[data-filters-panel]')

        button.addEventListener('click', (e: Event): void => {
            e.preventDefault();
            const panelIsOpen: string = panel.getAttribute('aria-hidden');
            panel.setAttribute('aria-hidden', panelIsOpen == 'true' ? 'false' : 'true');
        });
    }
}

document.addEventListener('htmx:afterSwap', (event) => {
    expandPanel();
    toggleFilterPanel();
});



document.addEventListener("DOMContentLoaded", () => {
    applyDarkMode();
    setupMenu();
    setupHeader();
    expandPanel();
    toggleFilterPanel();

    const logo: HTMLElement = document.querySelector('.header-logo .logo');

    const options: { root: null; rootMargin: string; threshold: number[] } = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1]
    };

    const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            if (entry.isIntersecting) {
                const element: Element = entry.target;

                if (element.classList.contains('page-banner')) {
                    const imgEl: HTMLImageElement = element.querySelector('img');
                    console.log(getImageColor(imgEl, 200, 200));
                }
            }
        });
    }, options);

    document.querySelectorAll('.main-body > section').forEach((section: Element): void => {
        observer.observe(section);
    });

    function getContrastColor(bgColor) {
        const rgb = bgColor.match(/\d+/g);
        const r = parseInt(rgb[0]);
        const g = parseInt(rgb[1]);
        const b = parseInt(rgb[2]);

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        return luminance > 0.5 ? 'black' : 'white';
    }

    async function getImageColor(element, x, y) {
        return new Promise((resolve, reject) => {
            const imageUrl = element.src;
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imageUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const pixelData = ctx.getImageData(x, y, 1, 1).data;
                const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`;
                resolve(color);
            };
            img.onerror = (error) => reject(error);
        });
    }
});

document.addEventListener('sprig:updated', expandPanel);

// Contact Index Map Init
let map;
async function initMap(): Promise<void> {
    const centerPosition = { lat: 52.1009166, lng: 5.6462914 };
    // Locations
    const locations = [
        { lat: 51.3633519, lng: 5.5942258, title: 'Hoofdlocatie Heeze' },
        { lat: 51.5912896, lng: 4.77146, title: 'Locatie Breda' },
    ];

    // @ts-ignore
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    // @ts-ignore
    const { Marker } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    map = new Map(
      document.getElementById('map') as HTMLElement,
      {
          zoom: 7,
          center: centerPosition,
      }
    );

    let marker;

    locations.forEach(location => {
        const { lat, lng, title } = location;

        marker = new Marker({
            map,
            position: { lat, lng },
            title
        })
    });
}

async function initLocationMap(el): Promise<void> {
    const center = JSON.parse(el.dataset.mapCenter);
    const title = el.dataset.mapTitle;

    // @ts-ignore
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    // @ts-ignore
    const { Marker } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    map = new Map(
      document.getElementById('map') as HTMLElement,
      {
          zoom: 10,
          center: center,
      }
    );

    new Marker({
        map,
        position: center,
        title,
    });
}

const contactIndexMap = document.querySelector('#map[data-map-type="contact-index-map"]');
if (contactIndexMap) {
    // @ts-ignore
    google.maps.importLibrary('maps').then(initMap);
}

const contactLocationMap = document.querySelector('#map[data-map-type="contact-location-map"]');
if (contactLocationMap) {
    // @ts-ignore
    google.maps.importLibrary('maps').then(initLocationMap(contactLocationMap));
}


// Content Policlinics Tabs
const locationsSelector: HTMLSelectElement = document.querySelector('#select-location');
const locationsTabs: NodeListOf<HTMLDivElement> = document.querySelectorAll('#select-location-contents > div');
if (locationsSelector && locationsTabs?.length) {
    locationsSelector.addEventListener('change', (evt: Event) => {
        const target = evt.target as HTMLSelectElement;

        locationsTabs.forEach(tab => {
            if (target.value === tab.dataset.tabValue) {
                tab.classList.remove('hidden');
            } else {
                tab.classList.add('hidden');
            }
        });
    });
}
